.banners-app {
  .vdr-action-bar {
    margin: 16px 0;
  }

  .banners-app__header-actions {
    display: flex;
    column-gap: 10px;
  }

  .banners-app__list-wrapper {
    overflow-x: auto;
  }
}
