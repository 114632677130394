.offers-app {
  a {
    cursor: pointer;
  }

  .vdr-action-bar {
    border-bottom: 1px solid #bfc3cc;
    gap: 20px;
    margin: 20px 0;
    padding: 20px 0;

    .left-content {
      margin-right: 20px;
      width: 100%;
    }
  }

  .offers-app__offer-search-input {
    margin-right: 20px;
    width: 100%;
  }

  .offers-app__offers {
    margin-top: 5px;
    width: 100%;
  }

  .offers-app__offers-headline {
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0 15px;
  }

  .offers-app__offers-headline + .offers-app__offers-headline {
    margin-top: 0;
  }

  .offers-app__tab-link {
    cursor: pointer;
    font-weight: normal;
    margin-right: 20px;
    opacity: 0.5;

    &--active {
      font-weight: bold;
      opacity: 1;
    }
  }

  .offers-app__offers-list {
    margin-top: 20px;
  }

  .offers-app__offers-article-number {
    background: var(--color-grey-500);
    border-radius: 5px;
    color: white;
    display: inline-block;
    font-size: 16px;
    line-height: 1.5rem;
    margin-left: 10px;
    padding: 0 10px;
  }

  .offers-app__offer {
    align-items: center;
    box-sizing: content-box;
    display: flex;
    line-height: 1.1rem;
    min-height: 80px;
    padding: 10px 50px 10px 20px;

    &:hover:not(&--skeleton) {
      background: #f2f3f5;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .offers-app__offer-image-box {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    height: 70px;
    margin-right: 50px;
    min-width: 60px;
    width: 60px;

    &--skeleton {
      display: block;
    }
  }

  .offers-app__offer-image {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    object-fit: initial;
  }

  .offers-app__label-container {
    margin: 0;

    &--skeleton {
      width: 100%;
    }
  }

  .offers-app__offer-name {
    font-size: 14px;
    margin: 0;

    span {
      color: var(--clr-color-danger-700);
      margin-right: 5px;
    }

    a {
      color: var(--color-primary-700);
    }
  }

  .offers-app__offer-info {
    font-size: 12px;
  }

  .offers-app__offer-divider {
    &:after {
      content: "•";
      margin: 0 5px;
    }
  }

  .offers-app__offer-info-sku {
    font-weight: bold;
  }

  .offers-app__offer-info-stock {
    background: var(--color-grey-100);
    display: inline-block;
    font-size: 12px;
    padding: 0 5px;
    margin-left: 5px;
    border-radius: 3px;
    color: var(--color-primary-900);
    min-width: 20px;
    text-align: center;
  }

  .offers-app__offer-info-sold-current {
    font-weight: bold;
    margin-right: 5px;
  }

  .offers-app__offer-info-sold-total {
    opacity: 0.6;
  }

  .offers-app__offer-info-stock--sold {
    background: var(--color-primary-100);
  }

  .offers-app__offer-info-stock--in-stock {
    background: var(--color-success-200);
  }

  .offers-app__offer-info-stock--low-stock {
    background: var(--color-warning-200);
  }

  .offers-app__offer-info-stock--out-of-stock {
    background: var(--color-error-200);
  }

  .offers-app__offer-info-stock--error {
    background: hotpink;
  }

  .offers-app__offer-info-date {
    color: currentColor;
    font-size: 11px;
    font-style: italic;
  }

  .offers-app__offer-actions {
    align-items: center;
    display: flex;
    margin-left: auto;

    &--skeleton {
      display: block;
      margin: 0 0 0 10%;
      width: 40%;
    }
  }

  .offers-app__offer-action {
    color: var(--color-primary-700);
    cursor: pointer;
    font-size: 14px;
    margin: 0 5vw 0 0;
    text-transform: uppercase;
    white-space: nowrap;

    &:last-of-type {
      margin: 0;
    }

    &--tag {
      border: 1px solid currentColor;
      color: #97979A;
      padding: 3px 8px;
    }
  }
}
