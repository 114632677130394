.toggle-switch {
  .toggleswitch__dot {
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: #aaa;
    display: block;
    border-radius: 25px;
    position: relative;
  }

  .toggleswitch__dot:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 15px;
    transition: 0.3s;
  }

  .toggle-switch__input {
    display: none;
  }

  .toggle-switch__input:checked + .toggleswitch__dot {
    background: var(--color-success-400);
  }

  .toggle-switch__input:checked + .toggleswitch__dot:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
}
