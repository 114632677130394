$form-width: 600px;

.banner-form {
  width: 100%;
  padding-bottom: 200px;

  .vdr-action-bar {
    margin: 16px 0;
  }

  .banner-form__header-actions {
    display: flex;
    column-gap: 10px;
  }

  .banner-form__content {
    // max-width: $form-width;
  }

  .banner-form__status {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .banner-form__status-toggle {
  }

  .banner-form__status-label {
  }

  .vdr-card {
    margin-top: calc(var(--space-unit) * 2);
  }

  .banner-form__field {
    max-width: $form-width;
    margin: 20px 4px;
    display: grid;
    grid-template-columns: 270px 1fr;
    align-items: center;
  }

  .banner-form__field-label {
    font-size: 14px;
    font-weight: 700;
  }

  .banner-form__field-input {
    &[type=checkbox] {
      width: 16px;
      height: 16px !important;
      cursor: pointer;
      accent-color: var(--clr-forms-checkbox-background-color, #0072a3);
    }
  }

  .banner-form__delete-button {
    margin-left: 5px;
  }

  .banner-form__description-field {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    gap: 4px;
  }

  .banner-form__field-colorpicker {
    align-items: center;
    display: flex;
  }

  .banner-form__field-datepicker {
    align-items: center;
    display: flex;
  }

  .banner-form__datepicker-input {
  }

  .banner-form__editor {
    .ql-toolbar {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .ql-container {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .ql-editor p {
      margin: 0;
    }

    .ql-editor {
      background: var(--color-form-input-bg);
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
