.date-picker {
  display: flex;

  &__input {
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &__icon-container {
    width: 60px;
    height: auto;
    border-radius: 0 3px 3px 0;
    border: 1px solid var(--color-grey-300);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:focus-within {
    .date-picker__icon-container {
      border-color: var(--color-primary-500);
      box-shadow: 0 0 1px 1px var(--color-primary-100);
      outline: none;
    }
  }
}
