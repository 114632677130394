/*
 * See https://github.com/vendure-ecommerce/vendure/blob/master/packages/admin-ui/src/lib/static/styles/theme/default.scss
 * for the complete list of customizable theme variables.
 */

:root [cds-theme] {
  --layout-content-max-width: none;
}

vdr-login {
  .login-wrapper {
    .login-title {
      line-height: 1;
    }
  }
}

vdr-simple-dialog {
  line-height: 1.2;
}

vdr-order-detail {
  h6 {
    text-decoration: underline;
  }

  vdr-tabbed-custom-fields + .button {
    display: none;
  }

  vdr-custom-field-control {
    label[for="customerNote"] ~ * textarea {
      border: 1px solid black;
      min-height: 100px;
    }
  }

  vdr-order-custom-fields-card {
    display: none;
  }

  vdr-page-detail-sidebar {
    vdr-labeled-data {
      font-size: 0.6rem;

      .label-title {
        border-bottom: 1px solid var(--color-grey-200);
        margin-bottom: 5px !important;
        margin-top: 10px;
        padding-bottom: 10px;
      }

      .content {
        font-weight: bold;
        margin-top: 0 !important;
        word-break: break-word;

        vdr-formatted-address {
          font-weight: normal;
          margin-top: 0 !important;

          // ul.address-lines
          li {
            line-height: 1.5;
          }

          .custom-field {
            display: none;
          }
        }
      }
    }

    vdr-payment-detail {
      vdr-object-tree {
        li {
          font-size: 0.6rem;
          font-weight: normal;
          margin-bottom: 5px;
          word-break: break-word;

          span.key {
            color: var(--color-secondary-500);
            display: block;
            margin-bottom: 3px;
            font-style: italic;
          }
        }
      }
    }
  }
}

vdr-product-variant-detail {
  .page-block {
    display: flex;
    flex-direction: column;

    vdr-card.assets-card {
      margin-top: calc(var(--space-unit) * 2);
      order: 999;
    }

    vdr-card + vdr-card {
      margin-top: calc(var(--space-unit) * 2);
    }

    vdr-custom-detail-component-host {
      height: 0;
    }

    vdr-react-custom-detail-component {
      margin-top: calc(var(--space-unit) * 2);
    }
  }
}
