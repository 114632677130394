.banner-list {
  color: var(--clr-table-font-color, #666666);
  background: var(--clr-table-bgcolor, white);
  border: 1px solid var(--color-component-border-100);
  border-radius: 2px;
  width: fit-content;
  min-width: 100%;

  .banner-list__items {
  }

  .banner-list__item {
    display: grid;
    grid-template-columns: 150px minmax(300px, 1fr) 180px 180px 100px 200px;
    gap: 8px;
    border-bottom: 1px solid var(--clr-tablerow-bordercolor, #e8e8e8)
  }

  .banner-list__header {
    font-size: 0.55rem;
    font-weight: 600;
    background: var(--clr-thead-bgcolor, #fafafa);
    border-bottom: 1px solid var(--clr-table-border-color, #cccccc);
  }

  .banner-list__column {
    padding: 0.55rem 0;
    min-width: 10%;
    display: flex;
    align-items: center;

    &:first-child {
      padding-left: 0.6rem;
    }

    &:last-child:not(&--center) {
      padding-right: 0.6rem;
    }

    &--center {
      justify-content: center;
    }
  }

  .banner-list__title {
    font-weight: bold;
  }

  .banner-list__title,
  .banner-list__description,
  .banner-list__date {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    overflow: hidden;
  }

  .banner-list__status {
    text-align: center;
    background-color: var(--color-component-bg-100);
    border: 1px solid var(--color-component-border-300);
    border-radius: 3px;
    margin: 6px;
    padding: 3px 6px;
    white-space: nowrap;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &--active {
      background: var(--color-success-400);
      border-color: var(--color-success-400);
      color: white;
    }
  }

  .banner-list__action {
    display: flex;
  }

  .banner-list__edit-button {
    display: flex;
    align-items: center;
    gap: 4px;

    cds-icon {
      margin-right: 5px;
    }
  }

  .banner-list__delete-button {
    margin-left: 5px;
  }
}
