.product-reviews-app {
  .vdr-page-detail-layout {
    grid-template-columns: 3fr 2fr;
  }

  .vdr-action-bar {
    border-bottom: 1px solid #bfc3cc;
    gap: 20px;
    margin: 10px 0;
    padding: 10px 0;

    .left-content {
      margin-right: 20px;
      width: 100%;
    }
  }

  .product-reviews-app__header-actions {
    display: flex;
    column-gap: 10px;
  }

  .product-reviews-app__search-input {
    width: 100%;
  }

  .product-reviews-app__products {
  }

  .product-reviews-app__product {
    align-items: center;
    background: #ffffff;
    // Leave this 'important' here, it's necessary to override the default styles.
    box-sizing: content-box !important;
    display: flex;
    height: 50px;
    padding: 20px 0 20px 0;

    &:hover:not(&--skeleton) {
      cursor: default;
      background: #f2f3f5;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .product-reviews-app__product--highlight {
  }

  .product-reviews-app__product--active {
    background: #f2f3f5;
  }

  .product-reviews-app__product-image-box {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    height: calc(100% + 20px);
    margin-right: 50px;
    min-width: 60px;
    width: 60px;

    &--skeleton {
      display: block;
    }
  }

  .product-reviews-app__product-image {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .product-reviews-app__product-label {
    font-size: 14px;
    margin: 0;
    width: 60%;

    a {
      color: var(--color-primary-700);
    }
  }

  .product-reviews-app__product-label-rate {
    font-size: 14px;
    margin: 0 0 0 auto;
    white-space: nowrap;

    &--skeleton {
      margin-left: 30px;
      width: 20%;
    }
  }

  .product-reviews-app__reviews {
  }

  .product-reviews-app__review-list {
    padding: 0 20px;
  }

  .product-reviews-app__review-list-head {
    display: flex;
    margin-bottom: 10px;
    margin-left: 40px;
    font-size: 14px;
  }

  .product-reviews-app__review-box {
    background: #ffffff;
    margin: 20px;
  }

  .product-reviews-app__review-box-head {
    align-items: center;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .product-reviews-app__review-box-body {
    border: 1px solid #cccccc;
    border-top: none;
    padding: 20px;
  }

  .product-reviews-app__review-box-row {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
  }

  .product-reviews-app__review-box-row-label {
    font-weight: 600;
    width: 30%;
  }

  .product-reviews-app__review-box-textarea {
    margin-top: 10px;
    min-height: 100px;
    resize: none;
    padding: 10px;
    width: 100%;
  }
}

.product-reviews-app__review-row-cell {
  margin-right: 5px;
  display: inline-block;

  &:last-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.product-reviews-app__review {
  align-items: center;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  display: flex;
  list-style: none;
  user-select: none;
  padding: 10px 20px 10px 15px;

  &:not([style*="hidden"]) + .product-reviews-app__review {
    border-top: none;
  }

  .product-reviews-app__review-drag {
    cursor: grab;
    display: flex;
    align-items: center;
    height: 40px;
  }

  .product-reviews-app__review-drag-icon {
    border-bottom: 2px solid #cccccc;
    border-top: 2px solid #cccccc;
    height: 6px;
    margin-right: 20px;
    width: 25px;
  }

  .product-reviews-app__review-row {
    display: flex;
    position: relative;
    width: 80%;
  }

  .product-reviews-app__review-quick-action {
    margin-left: 5px;
    padding: 5px;
  }
}
