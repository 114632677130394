vdr-react-custom-detail-component {
  .sales-figures {
    table {
      --cell-padding-horizontal: calc(0.5vw + 5px);

      @media (max-width: 1550px) {
        --cell-padding-horizontal: 5px;
      }

      table-layout: auto;

      .cell-content.placeholder {
        width: 100%;
      }

      thead {
        tr:last-of-type {
          th:last-of-type {
            border-image: none;
            border-inline-end: none;
          }
        }

        th {
          padding-left: var(--cell-padding-horizontal);
          padding-right: var(--cell-padding-horizontal);
          width: fit-content;
        }
      }

      tbody {
        tr:last-of-type {
          td:last-of-type {
            border-image: none;
            border-inline-end: none;
          }
        }

        td {
          border-bottom: none;
          padding-left: var(--cell-padding-horizontal);
          padding-right: var(--cell-padding-horizontal);
          width: fit-content;
          white-space: nowrap;
        }
      }
    }
  }
}
