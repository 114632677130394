.color-picker {
  display: flex;
  width: 100%;

  &__swatch {
    width: 40px;
    height: auto;
    border-radius: 3px 0 0 3px;
    border: 1px solid var(--color-grey-300);
    cursor: pointer;
  }

  &__popover-container {
    position: relative;
  }

  &__popover {
    z-index: 999;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  &__input {
    flex: 0 1 100%;
    border-left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &:focus-within {
    .color-picker__swatch {
      border-color: var(--color-primary-500);
      box-shadow: 0 0 1px 1px var(--color-primary-100);
      outline: none;
    }
  }
}
